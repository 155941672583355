import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 查询位置树
 */
export function getPositionList() {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/list',
        method: 'get',
    })
}

/**
 * 查询对应位置地图信息
 * @param params
 */
export function getPositionMap(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/digitalmap/getPosition/'+params.positionId,
        method: 'get',
    })
}

/**
 * 更新地图
 * @param params
 */
export function updateDigitalMap(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/digitalmap/update',
        method: 'post',
        data : params
    })
}

/**
 * 创建地图
 * @param params
 */
export function createDigitalMap(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/digitalmap/create',
        method: 'post',
        data : params
    })
}

/**
 * 查询不在位置地图中的设备
 * @param params
 */
export function getNotMapDevice(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/devicemap/selectNotDeviceList',
        method: 'get',
        params
    })
}

/**
 * 查询在位置地图中的设备
 * @param params
 */
export function getMapDevice(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/devicemap/selectDeviceListManager',
        method: 'get',
        params
    })
}

/**
 * 查询在位置地图中的设备状态信息
 * @param params
 */
export function getMapDeviceList(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/devicemap/selectDeviceList',
        method: 'get',
        params
    })
}

/**
 * 添加地图设备
 * @param params
 */
export function createDeviceMap(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/devicemap/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新地图设备位置
 * @param params
 */
export function updateDeviceMap(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/devicemap/update',
        method: 'post',
        data: params
    })
}


/**
 * 删除地图设备
 * @param params
 */
export function deleteDeviceMap(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/devicemap/delete/'+params.deviceMapId,
        method: 'delete',
    })
}

/**
 * 系统列表查询
 */
export function getCategoryList() {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/category/list',
        method: 'get',
    })
}

/**
 * 控制源分页查询
 * @param params
 */
export function setPoint(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/controlsource/setPoint',
        method: 'post',
        data: params
    })
}

/**
 * 查询项目列表
 * @param params
 */
export function getProjectList() {
    return request({
        url: envInfo.bgApp.basePath + `/tenant/project/list`,
        method: 'get',
    })
}

/**
 * 查询位置树
 */
export function getPositionTree(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/findPositionTree',
        method: 'get',
        params
    })
}
